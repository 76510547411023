import { useLocation } from 'react-router-dom';
import {
	Banner,
	ButtonJoin,
	CampaignTime,
	CampaignData,
	ClockIcon,
	Footer,
	FriendNumber,
	GroupIcon,
	HeaderTopRanking,
	Info,
	LeftBlock,
	Name,
	Participants,
	PopupContent,
	Prize,
	RankingList,
	RankingToken,
	RightBlock,
	User,
	UserAvatar,
	UserInfo,
	UserName,
	UserRanking,
	ViewDetailBtn,
	Wrapper,
	LayerBlur,
	CardTag,
	BannerWrapper,
	RankNumber,
	RankingWrapper,
	InfoFooter,
	LoadingWrapper,
	WrapperClaim,
	ButtonClaim,
	Challenge,
	MissionButton,
	MissionItem,
	MissionItemContent,
	MissionItemIcon,
	MissionList,
	MissionText,
	MissionTitle,
	TaskList,
	TaskListDesc,
	Status,
} from './index.styled';
import GiftIc from '../Icons/GiftIc';
import GoldMedalIc from '../Icons/GoldMedalIc';
import BronzeMedalIc from '../Icons/BronzeMedalIc';
import SilverMedalIc from '../Icons/SilverMedalIc';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector, RootState, useAppDispatch } from '../../redux/store';
import { CampaignRequest } from '../../service/campaign.request';
import {
	createMarkup,
	formatNumber,
	formatToken,
	getName,
	getStaleTime,
	hideName,
	renderFriendText,
	shortenNumber,
} from '../../utils/common';
import { toast } from 'react-toastify';
import PopupUI from '../UI/Popup/PopupUI';
import Countdown from 'react-countdown';
import TrophyIc from '../Icons/TrophyIc';
import { DotTag } from '../Ranking/index.styled';
import SectionTitle from '../UI/SectionTitle';
import { useQueryClient } from '@tanstack/react-query';
import {
	CAMPAIGN_TYPE,
	CampaignRewardStatus,
	DEFAULT_AVATAR,
	QUERY_KEYS,
} from '../../constants';
import Loading from '../UI/Loading';
import CongratulationModal from '../UI/CongratulationModal';
import { LoadingOutlined } from '@ant-design/icons';
import ProgressCircleUI from '../UI/ProgressCircle';
import TelegramIc from '../Icons/TelegramIc';
import ConfirmPaticipationModal from '../UI/ConfirmParticipationModal';
import BigNumber from 'bignumber.js';
import { getWalletReq } from '../../redux/actions/wallet.action';
import { getLoyaltyWalletReq } from '../../redux/actions/loyalty-wallet.action';
import { MISSION_CODE } from '../../constants/mission';

type TStatus = 'live' | 'finished';

const CampaignDetail = () => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const location = useLocation();

	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id');
	const campaignRequest = new CampaignRequest();
	const user = useAppSelector((state: RootState) => state.user);
	const currencies = useAppSelector((state) => state.wallet.currencies);
	const [campaign, setCampaign] = useState<Campaign>();
	const [myEvent, setMyEvent] = useState<Event>();
	const [loading, setLoading] = useState(false);
	const [myEventLoading, setMyEventLoading] = useState(false);
	const [ranking, setRanking] = useState<Ranking[]>([]);
	const [myRanking, setMyRanking] = useState<MyRanking>();
	const [myReward, setMyReward] = useState<MyReward>();
	const [openDetailPopup, setOpenDetailPopup] = useState(false);
	const [statusCampaign, setStatusCampaign] = useState<TStatus>('live');
	const [openCongratulation, setOpenCongratulation] = useState(false);
	const [joinCampaignLoading, setJoinCampaignLoading] = useState(false);
	const [openConfirmParticipationModal, setOpenConfirmParticipationModal] =
		useState(false);
	const balance = useAppSelector(
		(state: RootState) => state.loyaltyWallet.balance
	);
	const wallets = useAppSelector((state) => state.wallet.wallets);

	const getCurrencyBySymbol = (symbol: string) => {
		return currencies.find((currency) => currency.symbol === symbol);
	};
	const [isLoadingMission, setIsLoadingMission] = useState(new Set());

	const campaignMissions = useMemo(() => {
		if (!campaign || !myEvent) return [];
		const isVerifiedMissions = campaign.missionsInfo.filter((mission) =>
			myEvent.info?.completedMissions?.includes(mission.code)
		);
		const isNotVerifiedMissions = campaign.missionsInfo.filter(
			(mission) => !myEvent.info?.completedMissions?.includes(mission.code)
		);
		return [...isNotVerifiedMissions, ...isVerifiedMissions];
	}, [campaign, myEvent]);
	useEffect(() => {
		if (!(user?.id && id)) return;
		getCampaigns(id);
		getMyEvents(id);
		dispatch(getWalletReq());
	}, [user?.id, id]);

	const getCampaigns = async (id: string) => {
		try {
			setLoading(true);
			const res = await queryClient.fetchQuery({
				queryKey: [
					QUERY_KEYS.GET_CAMPAIGNS,
					{
						id,
					},
				],
				queryFn: () =>
					campaignRequest.getAll({
						id,
					}),
				staleTime: getStaleTime(5),
			});
			const data = res.data.data;
			if (data.length > 0) {
				const dataFirst = data[0];
				if (new Date(dataFirst.endAt).valueOf() < new Date().valueOf()) {
					setStatusCampaign('finished');
				}
				if (dataFirst.verifiedAt) {
					await getRewards(id);
					await getMyReward(id);
				} else {
					dataFirst?.type !== CAMPAIGN_TYPE.MISSION && (await getRanking(id));
				}
				dataFirst?.type !== CAMPAIGN_TYPE.MISSION && (await getMyRanking(id));
				setCampaign(dataFirst);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const getMyEvents = async (id: string) => {
		try {
			setMyEventLoading(true);
			const res = await queryClient.fetchQuery({
				queryKey: [
					QUERY_KEYS.GET_MY_EVENTS,
					{
						eventId: id,
					},
				],
				queryFn: () =>
					campaignRequest.getMyEvents({
						eventId: id,
					}),
				staleTime: getStaleTime(15),
			});
			const data = res.data.data;
			if (data.length > 0) setMyEvent(data[0]);
		} catch (error) {
			console.log(error);
		} finally {
			setMyEventLoading(false);
		}
	};
	const checkBalance = () => {
		if (!campaign) return;
		try {
			if (campaign.participationTokenSymbol === 'PATC') {
				if (new BigNumber(campaign.participationFee).isGreaterThan(balance)) {
					return false;
				}
			} else {
				const walletToken = wallets?.find(
					(token) => token.symbol == campaign.participationTokenSymbol
				);
				if (
					!walletToken ||
					new BigNumber(walletToken.availableBalance).isLessThan(
						campaign.participationFee
					)
				) {
					return false;
				}
			}
			return true;
		} catch (error) {
			console.log(error);
		}
	};

	const joinCampaign = async () => {
		if (!id) {
			toast.error('Campaign not found');
			return;
		}
		if (new BigNumber(campaign?.participationFee || 0).isGreaterThan(0)) {
			const isEnoughtBalance = checkBalance();
			if (!isEnoughtBalance) {
				toast.error('Insufficient balance!');
				return;
			}
		}
		try {
			await campaignRequest.joinCampaign(id);
			queryClient.invalidateQueries({
				queryKey: [
					QUERY_KEYS.GET_MY_EVENTS,
					{
						eventId: id,
					},
				],
			});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_CAMPAIGNS],
			});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_MY_RANKING],
			});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_RANKING, { id }],
			});
			if (campaign?.type == CAMPAIGN_TYPE.MISSION) {
				campaign.participationTokenSymbol !== 'PATC'
					? dispatch(getWalletReq())
					: dispatch(getLoyaltyWalletReq());
			}

			getMyEvents(id);
			getCampaigns(id);
			toast.success('Participate successfully');
		} catch (error: any) {
			console.log(error);
			toast.error(error?.response?.data?.message || 'Join campaign failed');
		}
	};

	const getMyReward = async (id: string) => {
		try {
			const res = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_MY_REWARDS, id],
				queryFn: () => campaignRequest.getMyReward(id),
				staleTime: getStaleTime(30),
			});
			if (res.data.data) {
				setMyReward(res.data.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getMyRanking = async (id: string) => {
		try {
			const res = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_MY_RANKING, id],
				queryFn: () => campaignRequest.getMyRanking(id),
				staleTime: getStaleTime(5),
			});
			if (res.data.data?.length > 0) {
				setMyRanking(res.data.data[0]);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getRanking = async (id: string) => {
		try {
			const res = await queryClient.fetchQuery({
				queryKey: [
					QUERY_KEYS.GET_RANKING,
					{
						id,
					},
				],
				queryFn: () =>
					campaignRequest.getRanking({
						id,
					}),
				staleTime: getStaleTime(5),
			});
			const userList = res.data.data || [];
			setRanking(userList);
		} catch (error) {
			console.log(error);
		}
	};

	const getRewards = async (id: string) => {
		try {
			const res = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_CAMPAIGN_REWARDS, id],
				queryFn: () => campaignRequest.getRewards(id),
				staleTime: getStaleTime(30),
			});
			setRanking(
				res.data.data
					.map((item: any) => ({
						...item.info,
						...item,
					}))
					.sort((a: any, b: any) => Number(a?.rank) - Number(b?.rank))
			);
		} catch (error) {
			console.log(error);
		}
	};

	const renderRanking = (ranking: number) => {
		switch (ranking) {
			case 1:
				return <GoldMedalIc />;
			case 2:
				return <SilverMedalIc />;
			case 3:
				return <BronzeMedalIc />;
			default:
				return `#${ranking}`;
		}
	};

	const renderer = ({ days, hours, minutes, seconds }: any) => {
		return (
			<span>
				End in: {days} days, {hours.toString().padStart(2, 0)}:
				{minutes.toString().padStart(2, 0)}:{seconds.toString().padStart(2, 0)}
			</span>
		);
	};

	const getDate = (campaign: Campaign) => {
		if (new Date(campaign.startAt).valueOf() > new Date().valueOf()) {
			return new Date(campaign.startAt).valueOf();
		}
		return new Date(campaign.endAt).valueOf();
	};

	const claimReward = async (id?: string | null) => {
		if (!id || loading) return;
		try {
			setJoinCampaignLoading(true);
			await campaignRequest.claimReward(id);
			if (campaign?.type === CAMPAIGN_TYPE.MISSION) {
				queryClient.invalidateQueries({
					queryKey: [QUERY_KEYS.GET_MY_EVENTS],
				});
				await getMyEvents(id);
			}
			setOpenCongratulation(true);
		} catch (error: any) {
			toast.error(error?.response?.data?.message || 'Claim failed');
		} finally {
			setJoinCampaignLoading(false);
		}
	};

	const claimMission = async (eventId: string, mission: CampaignMission) => {
		await campaignRequest.claimMission(eventId, mission.code);
		queryClient.invalidateQueries({
			queryKey: [QUERY_KEYS.GET_MY_EVENTS],
		});
		await getMyEvents(eventId);
	};

	const verifyMission = async (mission: CampaignMission) => {
		if (!id || !myEvent || isLoadingMission.has(mission.code)) return;
		try {
			setIsLoadingMission((prevState) => {
				const newState = new Set(prevState);
				newState.add(mission.code);
				return newState;
			});
			if (myEvent.info?.verifyingMissions?.includes(mission.code)) {
				claimMission(id, mission);
			} else {
				await campaignRequest.startMission(id, mission.code);
				if (mission.url) {
					const tg = (window as any)?.Telegram.WebApp;
					if (
						(
							[
								MISSION_CODE.JOIN_APES_TELEGRAM_CHANNEL,
								MISSION_CODE.JOIN_APES_TELEGRAM_CHAT,
							] as string[]
						)?.includes(mission.code)
					) {
						tg?.openTelegramLink(mission.url);
					} else {
						tg?.openLink(mission.url);
					}
				}
				await new Promise((resolve) => setTimeout(resolve, 1000));
				await claimMission(id, mission);
			}
		} catch (error: any) {
			console.log(error);
		} finally {
			setIsLoadingMission((prevState) => {
				const newState = new Set(prevState);
				newState.delete(mission.code);
				return newState;
			});
		}
	};
	const onCloseCongratulationModal = () => {
		setOpenCongratulation(false);
		if (!id) return;
		queryClient.invalidateQueries({
			queryKey: [QUERY_KEYS.GET_CAMPAIGNS],
		});
		queryClient.invalidateQueries({
			queryKey: [QUERY_KEYS.GET_CAMPAIGN_REWARDS],
		});
		queryClient.invalidateQueries({
			queryKey: [QUERY_KEYS.GET_MY_REWARDS, id],
		});
		getCampaigns(id);
		getMyEvents(id);
	};

	return (
		<>
			<Wrapper
				isPaddingBottom={
					(myReward?.status === CampaignRewardStatus.PENDING &&
						statusCampaign === 'finished') ||
					(myEvent?.info?.completedMissions?.length ===
						myEvent?.missions?.length &&
						!myEvent?.info?.isClaimed)
				}
			>
				{loading || myEventLoading ? (
					<Loading />
				) : !campaign ? (
					<LoadingWrapper>
						<div>Campaign Not found</div>
					</LoadingWrapper>
				) : (
					<>
						<BannerWrapper>
							<Banner src={campaign.image} alt="banner" />
							<StatusTag status={statusCampaign} />
						</BannerWrapper>
						<Info>
							<Name>{campaign.name}</Name>
							<CampaignData>
								{campaign.type !== CAMPAIGN_TYPE.MISSION && (
									<CampaignTime>
										<ClockIcon src="/images/referral/clock.svg" alt="clock" />
										{new Date(campaign.endAt).valueOf() <
										new Date().valueOf() ? (
											'End of campaign'
										) : (
											<Countdown date={getDate(campaign)} renderer={renderer} />
										)}
										{/* {`${formatEventDate(campaign.startAt)}-${formatEventDate(
										campaign.endAt
									)}`} */}
									</CampaignTime>
								)}
								<Participants
									style={{
										justifyContent:
											campaign.type === CAMPAIGN_TYPE.MISSION
												? 'flex-start'
												: 'flex-end',
										textAlign:
											campaign.type === CAMPAIGN_TYPE.MISSION
												? 'left'
												: 'right',
									}}
								>
									<GroupIcon
										src="/images/referral/group-users.svg"
										alt="group"
									/>{' '}
									{shortenNumber(campaign.participants)}{' '}
									{campaign.participants > 1 ? 'Participants' : 'Participant'}
								</Participants>
								{campaign.type === CAMPAIGN_TYPE.MISSION && (
									<Status
										status={
											!myEvent
												? 'unjoined'
												: myEvent.info.isClaimed
												? 'completed'
												: 'joined'
										}
									>
										{!myEvent
											? 'PARTICIPATE NOW'
											: myEvent.info.isClaimed
											? 'COMPLETED'
											: 'PARTICIPATED'}
									</Status>
								)}
							</CampaignData>
							<Prize>
								<GiftIc /> {campaign.prizeInfo}
							</Prize>
							<Challenge>
								<TrophyIc /> {campaign.shortDescription}
							</Challenge>

							<InfoFooter>
								<ViewDetailBtn onClick={() => setOpenDetailPopup(true)}>
									View details
								</ViewDetailBtn>
							</InfoFooter>
						</Info>
						{!myEvent && statusCampaign !== 'finished' && (
							<Footer>
								<ButtonJoin
									onClick={() => {
										if (campaign?.type === CAMPAIGN_TYPE.MISSION) {
											setOpenConfirmParticipationModal(true);
											return;
										}
										joinCampaign();
									}}
								>
									{campaign.type !== CAMPAIGN_TYPE.MISSION
										? 'Join Campaign'
										: `${formatToken(campaign.participationFee)} ${
												campaign.participationTokenSymbol
										  } to Participate`}
								</ButtonJoin>
							</Footer>
						)}
						{!!myEvent && campaign.missionsInfo && (
							<>
								<TaskList>Task list:</TaskList>
								<TaskListDesc>
									Complete simple tasks, gain community exposure,
									<br /> and earn big rewards!
								</TaskListDesc>
								<MissionList>
									{campaignMissions.map((mission, key) => (
										<MissionItem key={key}>
											<MissionItemContent>
												<MissionItemIcon>
													{mission.icon ? (
														<img src={mission.icon} alt={mission.name} />
													) : (
														<TelegramIc />
													)}
												</MissionItemIcon>
												<MissionText>
													<MissionTitle>{mission.name}</MissionTitle>
												</MissionText>
											</MissionItemContent>
											{myEvent &&
											myEvent.info?.completedMissions?.includes(
												mission.code
											) ? (
												<ProgressCircleUI percent={100} />
											) : (
												<MissionButton
													disabled={!myEvent}
													loading={isLoadingMission.has(mission.code)}
													onClick={() => verifyMission(mission)}
												>
													Start
												</MissionButton>
											)}
										</MissionItem>
									))}
								</MissionList>
							</>
						)}
						{campaign && campaign.type !== CAMPAIGN_TYPE.MISSION && (
							<RankingWrapper>
								{(myEvent || ranking.length > 0) && (
									<HeaderTopRanking>
										<SectionTitle text="Top Ranking" />
									</HeaderTopRanking>
								)}
								{myEvent && (
									<UserRanking>
										<LeftBlock>
											<RankNumber>
												{campaign?.verifiedAt && myReward?.info.rank
													? `#${myReward?.info.rank}`
													: myRanking?.rank
													? `#${myRanking.rank}`
													: '#--'}
											</RankNumber>
											<UserAvatar
												src={user?.avatar || DEFAULT_AVATAR}
												alt="avatar"
												style={{
													border: user?.avatar ? '1px solid #fff' : 'none',
												}}
											/>
											<UserInfo>
												<UserName>
													{getName(
														user.firstName,
														user.lastName,
														user.username
													)}
												</UserName>
												<FriendNumber>
													{renderFriendText(
														parseInt(
															!!campaign?.verifiedAt
																? myReward?.info.totalFriends || '0'
																: myRanking?.totalFriends || '0'
														)
													)}
												</FriendNumber>
											</UserInfo>
										</LeftBlock>
										<RightBlock>
											{myReward?.reward ? (
												<>
													{formatToken(myReward.reward || 0, 10)}
													<RankingToken
														src={
															getCurrencyBySymbol(myReward.symbol)?.logo || ''
														}
														alt="symbol"
													/>
												</>
											) : (
												''
											)}
										</RightBlock>
									</UserRanking>
								)}
								{campaign.type !== CAMPAIGN_TYPE.MISSION && (
									<RankingList>
										{ranking.map((user, index) => (
											<User key={index}>
												<LeftBlock>
													<RankNumber>
														{renderRanking(parseInt(user.rank))}
													</RankNumber>
													<UserAvatar
														src={user.avatar || DEFAULT_AVATAR}
														alt="avatar"
														style={{
															border: user?.avatar ? '1px solid #fff' : 'none',
														}}
													/>
													<UserInfo>
														<UserName>
															{hideName(
																getName(
																	user.firstName,
																	user.lastName,
																	user.username
																)
															)}
														</UserName>
														<FriendNumber>
															{renderFriendText(parseInt(user.totalFriends))}
														</FriendNumber>
														<FriendNumber>
															{formatToken(user.availableBalance)} PATC
														</FriendNumber>
													</UserInfo>
												</LeftBlock>
												<RightBlock>
													{user.reward ? (
														<>
															{formatToken(user.reward || 0, 10)}{' '}
															<RankingToken
																src={`${
																	getCurrencyBySymbol(user.symbol)?.logo || ''
																}`}
																alt="symbol"
															/>
														</>
													) : (
														''
													)}
												</RightBlock>
											</User>
										))}
									</RankingList>
								)}
								<LayerBlur />
							</RankingWrapper>
						)}
					</>
				)}
			</Wrapper>
			{campaign && campaign.type !== CAMPAIGN_TYPE.MISSION ? (
				myReward?.status === CampaignRewardStatus.PENDING &&
				statusCampaign === 'finished' && (
					<WrapperClaim>
						<ButtonClaim
							$disable={joinCampaignLoading}
							onClick={() => claimReward(id)}
						>
							{joinCampaignLoading && <LoadingOutlined />} Claim{' '}
							{myReward.reward || 0} {myReward.symbol}
						</ButtonClaim>
					</WrapperClaim>
				)
			) : (
				<>
					{myEvent &&
						myEvent.info &&
						myEvent.info.completedMissions?.length ===
							myEvent.missions?.length &&
						!myEvent.info.isClaimed && (
							<WrapperClaim>
								<ButtonClaim
									$disable={joinCampaignLoading}
									onClick={() => claimReward(id)}
								>
									{joinCampaignLoading && <LoadingOutlined />} Claim{' '}
									{formatToken(myEvent.info.rewardAmount)}{' '}
									{myEvent.info.rewardSymbol}
								</ButtonClaim>
							</WrapperClaim>
						)}
				</>
			)}
			<PopupUI
				open={openDetailPopup}
				onClose={() => setOpenDetailPopup(false)}
				title="Detail Campaign"
			>
				<PopupContent
					id="campaign-details"
					dangerouslySetInnerHTML={createMarkup(campaign?.description || '')}
				/>
			</PopupUI>
			{myReward && (
				<CongratulationModal
					open={openCongratulation}
					onClose={onCloseCongratulationModal}
					type="token"
					tokenImage={getCurrencyBySymbol(myReward.symbol)?.logo || ''}
					tokenSymbol={myReward.symbol}
					number={Number(myReward.reward)}
					description={`You have earned ${formatNumber(myReward.reward || 0)} ${
						myReward.symbol
					} from Campaign`}
				/>
			)}
			{myEvent?.info?.isClaimed && (
				<CongratulationModal
					open={openCongratulation}
					onClose={onCloseCongratulationModal}
					type="token"
					tokenImage={
						getCurrencyBySymbol(myEvent.info.rewardSymbol)?.logo || ''
					}
					tokenSymbol={myEvent.info.rewardSymbol}
					number={Number(myEvent.info.rewardAmount)}
					description={`You have earned ${formatNumber(
						myEvent.info.rewardAmount || 0
					)} ${myEvent.info.rewardSymbol} from Campaign`}
				/>
			)}
			<ConfirmPaticipationModal
				open={openConfirmParticipationModal}
				onClose={() => setOpenConfirmParticipationModal(false)}
				number={Number(campaign?.participationFee)}
				tokenSymbol={campaign?.participationTokenSymbol || 'PATC'}
				onConfirm={joinCampaign}
				confirmText="To participate in the Campaign, users need to spend"
			/>
		</>
	);
};

export const StatusTag = ({ status }: { status: TStatus }) => {
	return (
		<CardTag $type={status}>
			<DotTag /> <span>{status}</span>
		</CardTag>
	);
};

export default CampaignDetail;
