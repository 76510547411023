import { useEffect, useState } from 'react';
import ArrowSquareLeftIc from '../Icons/ArrowSquareLeftIc';
import {
	Cards,
	FilterBlock,
	Header,
	StyledIDOCard,
	StyledSectionTitle,
	Wrapper,
} from './IDOList.styled';
import FilterPopup from './FilterPopup';
import { QuerySearchStatus } from '../../constants/launchpad';
import fairlaunchRequest from '../../service/fairlaunch.request';
import { normalizeDataForProjectCard } from '../../utils/launchpad';
import { QUERY_KEYS } from '../../constants';
import { useQueryClient } from '@tanstack/react-query';
import { getStaleTime } from '../../utils/common';
import InfiniteLoad from '../../hooks/useInfiniteLoading';
const PAGE_SIZE = 10;
const DEFAULT_PARAMS = {
	page: 1,
	pageSize: PAGE_SIZE,
	searchStatus: QuerySearchStatus.ALL,
	order: 'updatedAt desc',
};
const IDOList = () => {
	const queryClient = useQueryClient();
	const [open, setOpen] = useState(false);
	const [params, setParams] = useState<PoolContentParams>({
		...DEFAULT_PARAMS,
	});
	const [total, setTotal] = useState<number>(0);
	const [launchpads, setLaunchpads] = useState<IDOItem[]>([]);
	const [loading, setLoading] = useState(true);
	const [totalPage, setTotalPage] = useState(1);

	useEffect(() => {
		getPools(params);
	}, [JSON.stringify(params)]);

	const getPools = async (params: PoolContentParams) => {
		try {
			if (params.page && params.page > totalPage) return;
			setLoading(true);
			const res = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_POOLS, params],
				queryFn: () => fairlaunchRequest.getPoolContent(params),
				staleTime: getStaleTime(15),
			});
			const formatedData = res.data.data.map((item: PoolContent) =>
				normalizeDataForProjectCard(item)
			);
			const newLaunchpads =
				params.page === 1 ? formatedData : [...launchpads, ...formatedData];
			setLaunchpads(newLaunchpads);
			setTotal(res.data.meta.totalRecord);
			setTotalPage(res.data.meta.totalPage || 1);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<Wrapper>
				<Header>
					<StyledSectionTitle text={`All Launchpads (${total})`} />
					<FilterBlock onClick={() => setOpen(true)}>
						Filter By
						<ArrowSquareLeftIc />
					</FilterBlock>
				</Header>
				<InfiniteLoad
					loading={loading}
					params={params}
					setParams={setParams}
					data={launchpads}
					notFoundContent={<div> No project found. </div>}
				>
					<Cards>
						{launchpads.map((launchpad, index) => (
							<StyledIDOCard {...launchpad} key={index} />
						))}
					</Cards>
				</InfiniteLoad>
			</Wrapper>
			<FilterPopup
				params={params}
				setParams={setParams}
				open={open}
				setOpen={setOpen}
			/>
		</>
	);
};

export default IDOList;
