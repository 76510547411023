import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SectionTitle from '../UI/SectionTitle';
import { LoyaltyWalletRequest } from '../../service/loyalty-wallet.request';
import { LoyaltyRewardType, QUERY_KEYS } from '../../constants';
import { formatNumber, getStaleTime } from '../../utils/common';
import { useQueryClient } from '@tanstack/react-query';

const YourRewards = () => {
	const queryClient = useQueryClient();
	const loyaltyRquest = new LoyaltyWalletRequest();
	const [rewards, setRewards] = useState<
		{ amount: string; type: string; info?: any }[]
	>([]);
	const accountAge = rewards.find(
		(reward) => reward.type === LoyaltyRewardType.TELEGRAM_AGE
	);

	const telegramAccount = rewards.find(
		(reward) => reward.type === LoyaltyRewardType.TELEGRAM_ACCOUNT
	);

	const inviter = rewards.find(
		(reward) => reward.type === LoyaltyRewardType.INVITER
	);

	useEffect(() => {
		getRewards();
	}, []);

	const getRewards = async () => {
		const types = [
			LoyaltyRewardType.TELEGRAM_AGE,
			LoyaltyRewardType.TELEGRAM_ACCOUNT,
			LoyaltyRewardType.INVITER,
		];
		try {
			const res = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_LOYALTY_REWARDS, types],
				queryFn: () => loyaltyRquest.getLoyaltyRewards(types),
				staleTime: getStaleTime(15),
			});
			setRewards(res.data.data);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Wrapper>
			<LayerBlur />
			<SectionTitle text="Your Rewards" />
			<Container>
				<ListReward>
					{!!accountAge && (
						<RewardWrapper>
							<Reward>
								<RewardIcon>
									<GifIcon src="/images/landing/cake.png" alt="reward-age" />
								</RewardIcon>
								<RewardContent>
									<RewardTitle>Account Age</RewardTitle>
									<RewardAmount>
										+{formatNumber(accountAge?.amount || 0)}{' '}
										<TokenIc src="/images/tokens/patc.svg" alt="patc" />{' '}
									</RewardAmount>
								</RewardContent>
							</Reward>
						</RewardWrapper>
					)}
					{!!telegramAccount && (
						<RewardWrapper>
							<Reward>
								<RewardIcon>
									<GifIcon
										src="/gifs/telegram-premium.gif"
										alt="telegram-premium"
									/>
								</RewardIcon>
								<RewardContent>
									<RewardTitle>
										Telegram{' '}
										{!!telegramAccount?.info?.telegramPremium
											? 'Premium'
											: 'Regular'}
									</RewardTitle>
									<RewardAmount>
										+{formatNumber(telegramAccount?.amount || 0)}{' '}
										<TokenIc src="/images/tokens/patc.svg" alt="patc" />{' '}
									</RewardAmount>
								</RewardContent>
							</Reward>
						</RewardWrapper>
					)}
					{!!inviter && (
						<RewardWrapper isPaddingRight>
							<Reward>
								<RewardIcon>
									<GifIcon
										src="/gifs/invite-friends.gif"
										alt="invite-friends"
									/>
								</RewardIcon>
								<RewardContent>
									<RewardTitle>Invite Friends</RewardTitle>
									<RewardAmount>
										+{formatNumber(inviter?.amount || 0)}{' '}
										<TokenIc src="/images/tokens/patc.svg" alt="patc" />{' '}
									</RewardAmount>
								</RewardContent>
							</Reward>
						</RewardWrapper>
					)}
				</ListReward>
			</Container>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: fit-content;
`;
const LayerBlur = styled.div`
	position: absolute;
	top: -100px;
	right: -220px;
	z-index: -1;
	width: 326px;
	height: 327px;
	flex-shrink: 0;

	border-radius: 327px;
	background: #0084ff;
	filter: blur(100px);
`;
const RewardWrapper = styled.div<{ isPaddingRight?: boolean }>`
	padding-right: ${(props) => (props.isPaddingRight ? '16px' : 0)};
	width: 100%;
`;
const Reward = styled.div`
	border-radius: 10px;
	background: rgba(255, 255, 255, 0.1);

	display: flex;
	padding: 6px 10px;
	align-items: center;
	min-width: fit-content;
	gap: 8px;
`;

const Container = styled.div`
	max-width: 100%;
	overflow-x: auto;
	padding: 0 16px;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

const ListReward = styled.div`
	display: flex;
	gap: 12px;
	padding-bottom: 12px;
`;

const RewardIcon = styled.div`
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: linear-gradient(
		224deg,
		rgba(255, 255, 255, 0.2) 1.8%,
		rgba(255, 255, 255, 0) 100%
	);
`;
const GifIcon = styled.img`
	width: 24px;
	height: 24px;
	object-fit: contain;
`;
const RewardVideo = styled.video`
	width: 24px;
	height: 24px;
	object-fit: contain;
	background-color: transparent !important;
`;
const RewardContent = styled.div``;
const RewardTitle = styled.div`
	color: var(--Main, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 216.667% */
	white-space: nowrap;
`;
const RewardAmount = styled.div`
	color: var(--Main, var(--Native-text_color, #fff));
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px; /* 128.571% */

	display: flex;
	align-items: center;
	gap: 4px;
`;
const TokenIc = styled.img`
	width: 16px;
	height: 16px;
`;

export default YourRewards;
